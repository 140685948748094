import { Link } from "gatsby"
import React, { useState, useContext, useEffect} from 'react'
import PropTypes from "prop-types"
import styled from 'styled-components'
import logoDesktop from "@images/logos/logo-black-3.svg"
import logoMobile from "@images/logos/logo-mobile.svg"
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { useBoxLayout } from '@hooks/useBoxLayout'
import { navigate } from "gatsby"
import { Button } from '@components/common'
import { useTranslation } from 'react-i18next'
import AudioPlayerContext from '@components/audioplayer/AudioPlayerContext'
import {FirebaseContext} from '@components/Firebase'
import DiscountIcon from '@images/discount-icon.png'

const MenuWrapper = styled.div` 
  padding:${props => (props.boxLayout || props.user) ? '0px 20px;' : '0px 10%;'};
  box-shadow: -12px 4px 61px -16px rgba(0,0,0,0.41);
  z-index: 999;
  top: 0px;
  display:${props => (!props.visible || (props.playerMode === "fullscreen") ) ? 'none' : 'flex'};
  position:fixed;
  justify-content:center;
  align-items:center;
  width:${props => (props.boxLayout || props.user) ? '600px' : '100%'};
  background-color:white;
  height:80px; 
  margin-top:${props => props.boxLayout ? '' : ''};
  max-width:100%;
  @media ${props => props.theme.phonePort} {
    padding: 0px 10px;
    justify-content:space-between;
  }
`

const RegisterLink= styled(Link)`
  transition: all .1s ease;
  text-decoration:none;
  color:white;
  background-color:${props => props.theme.secondaryColor};
  padding:10px 50px;
  border-radius:25px;
  @media ${props => props.theme.phonePort} {
    padding: 10px 25px;
    font-size:0.8rem;
    order:1;
  }
`


const LogoLink = styled(Link)`
  height:60px;
  flex-grow: 1;
  @media ${props => props.theme.phonePort} {
    flex-grow: 0.25;
  }
`
const MobileLogo = styled.img`
  height:30px;
  display:none;
  @media ${props => props.theme.phonePort} {
   display:block;
  }
`

const LogoWrapper = styled.div`
  height:100%;
  display:grid;
  place-items:center;
  align-items: center;
  @media ${props => props.theme.tabletUp} {
    place-items:start;
    align-items: center;
  }
`

const LogoDesktop = styled.img`
  height:35px;
  @media ${props => props.theme.phoneLand} {
   
  }
  @media ${props => props.theme.phonePort} {
   display:none;
  }
`

const LoginLink= styled(Link)`
  flex-grow:1;
  font-family:${props => props.theme.mainFontParagraph};
  margin: 0px 30px;
  text-decoration:none;
  color:${props => props.theme.primaryColor};
  place-items:center;
  @media ${props => props.theme.phonePort} {
    margin: 0px 0px;
    flex-grow: 0;
    font-size:0.8rem;
    order:2;
  }
 
  `

const ButtonsWrapper = styled.div`
  font-family:${props => props.theme.mainFontParagraph};
  text-transform:uppercase;
  @media ${props => props.theme.phonePort} {
    display:flex;
    flex-grow: 0.75;
    align-items: center;
    justify-content: space-around;
  }
`
const Icon = styled.img`
  height:35px;
  width:35px;
  position:absolute;
  top:50%;
  transform: translateY(-50%);
  left:35px;
`;
const Header = (props) => {
  /* 
    Props Explanation:
    alwaysShow: forces displaying of header for example in blog post 
    location: used to determine whether mobile layout is used or not
    forceHide: used to hide "Get Premium"-CTA on sign up pages
  */
  const {alwaysShow, location, forceHide} = props;
  const { t } = useTranslation()
  const [isVisible, setIsVisible] = useState(alwaysShow)
  const {playerMode} = useContext(AudioPlayerContext);
  const {user} = useContext(FirebaseContext);
  const boxLayout =  useBoxLayout(location)

  useEffect(() => {if(forceHide){setIsVisible(false)} },[forceHide])


  useScrollPosition(
    ({ currPos }) => {
        if(!alwaysShow && !forceHide){
          setIsVisible(currPos.y < 0)
        } 
    },
    []
  )

  function redirectToSignUp(){
    navigate(t('urls.sign-up'))
  }

  return (
    <>
      {/* uncomment bellow for floating profile */}
      {/* {(user && !forceHide && location.pathname !== t('urls.search')) && <Link  to={t('urls.profile')} ><ProfileCircleWrapper isVisible={isVisible && !user.isSubscriber ? 'yes' : 'no'}><ProfileCircle boxLayout={boxLayout} ></ProfileCircle></ProfileCircleWrapper></Link> } */}
      { (!user || (!user.isSubscriber && !forceHide))  &&
      <>
      <MenuWrapper visible={isVisible} boxLayout={boxLayout} playerMode={playerMode} user={user}>
        {user ?
        <>
          <Button block onClick={redirectToSignUp} value = {true} >
          {/*   <Icon src={DiscountIcon} /> */}
            {t('header.cta-1')}
          </Button>
        </>
        :
        <>
          <LogoLink to={t('urls.root')} >
            <LogoWrapper>
              <MobileLogo src={logoMobile} loading="lazy" alt="Audiodesires mobile logo"/>
              <LogoDesktop src={logoDesktop} loading="lazy" alt="Audiodesires desktop logo"/>
            </LogoWrapper>
          </LogoLink>
          <ButtonsWrapper>
            <LoginLink to={t('urls.login')} >{t('header.login')}</LoginLink>
            <RegisterLink to={t('urls.register')} >{t('header.cta-2')}</RegisterLink>
          </ButtonsWrapper>
        </>
        }
      </MenuWrapper>
      </>
      }
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  forceHide: PropTypes.bool,
}

Header.defaultProps = {
  siteTitle: ``,
  forceHide:false,
}

export default Header
