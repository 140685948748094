import React from "react"
import Text from '@typo/Text'
import MainHeading from '@typo/MainHeading'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Header from "@menus/header"

const PrivacyPolicy = styled.div`
  background-color: white;
  max-width:1200px;
  margin:auto;
  padding-top:80px;
  @media (max-width: 1000px) {
    max-width:90%;
  }
`


const Terms = (props) => {
  const { t } = useTranslation()

  return (
    <>
    <Header location={props.location} alwaysShow={true}/> 
      <PrivacyPolicy>
        <MainHeading center>{t('privacy-policy.sectionHeading')}</MainHeading>
        <Text center>{t('privacy-policy.text-1')}</Text>
        <h2>{t('privacy-policy.heading-1')}</h2>
        <Text>{t('privacy-policy.text-2')}</Text>
        <Text>{t('privacy-policy.text-3')}</Text>
        <Text>{t('privacy-policy.text-4')}</Text>
        <Text>{t('privacy-policy.text-5')}</Text>
        <h2>{t('privacy-policy.heading-2')}</h2>
        <Text>{t('privacy-policy.text-6')}</Text>
        <h2>{t('privacy-policy.heading-3')}</h2>
        <h3>{t('privacy-policy.heading-4')}</h3>
        <Text>{t('privacy-policy.text-7')}</Text>
        <h3>{t('privacy-policy.heading-5')}</h3>
        <Text>
        {t('privacy-policy.text-8')}
      
        </Text>
        <ol>
          <li>{t('privacy-policy.listItem-1')}</li>
          <li>{t('privacy-policy.listItem-2')}</li>
          <li>{t('privacy-policy.listItem-3')}</li>
          <li>{t('privacy-policy.listItem-4')}</li>
          <li>{t('privacy-policy.listItem-5')}</li>
          <li>{t('privacy-policy.listItem-6')}</li>
        </ol>
        <h3>{t('privacy-policy.heading-6')}</h3>
        <Text>{t('privacy-policy.text-9')}</Text>
        <h2>{t('privacy-policy.heading-7')}</h2>
        <Text>
          {t('privacy-policy.text-10')}
   
        </Text>
        <ol>
          <li>{t('privacy-policy.listItem-7')}</li>
          <li>{t('privacy-policy.listItem-8')}</li>
          <li>{t('privacy-policy.listItem-9')}</li>
          <li>{t('privacy-policy.listItem-10')}</li>
          <li>{t('privacy-policy.listItem-11')}</li>
          <li>{t('privacy-policy.listItem-12')}</li>
          <li>{t('privacy-policy.listItem-13')}</li>
          <li>{t('privacy-policy.listItem-14')}</li>
          <li>{t('privacy-policy.listItem-15')}</li>
        </ol>


        <h2>{t('privacy-policy.heading-8')}</h2>
        <Text>{t('privacy-policy.text-11')}
       
        </Text>
        <ol>
          <li>{t('privacy-policy.listItem-16')}</li>
          <li>{t('privacy-policy.listItem-17')}</li>
          <li>{t('privacy-policy.listItem-18')}</li>
          <li>{t('privacy-policy.listItem-19')}</li>
          <li>{t('privacy-policy.listItem-20')}</li>
          <li>{t('privacy-policy.listItem-21')}</li>
        </ol>
        <h2>{t('privacy-policy.heading-9')}</h2>
        <Text>{t('privacy-policy.text-12')}
         
        </Text>
        <h2>{t('privacy-policy.heading-10')}</h2>
        <Text>{t('privacy-policy.text-13')}
          
        </Text>
        <h2>{t('privacy-policy.heading-11')}</h2>
        <h3>{t('privacy-policy.heading-12')}</h3>
        <Text>{t('privacy-policy.text-14')}
        
        </Text>


        <h3>{t('privacy-policy.heading-13')}</h3>
        <Text>{t('privacy-policy.text-15')}
        
        </Text>

        <h3>{t('privacy-policy.heading-14')}</h3>
        <Text>{t('privacy-policy.text-16')}
        
        </Text>

        <h3>{t('privacy-policy.heading-15')}</h3>
        <Text>{t('privacy-policy.text-17')}
        
        </Text>

        <h3>{t('privacy-policy.heading-16')}</h3>
        <Text>{t('privacy-policy.text-18')}
        
        </Text>
        <ol>
          <li>{t('privacy-policy.listItem-22')}</li>
          <li>{t('privacy-policy.listItem-23')}</li>
          <li>{t('privacy-policy.listItem-24')}</li>
          <li>{t('privacy-policy.listItem-25')}</li>
        </ol>
        <h3>{t('privacy-policy.heading-17')}</h3>
        <Text>{t('privacy-policy.text-19')}
  
        </Text>
        <h3>{t('privacy-policy.heading-18')}</h3>
        <Text>{t('privacy-policy.text-20')}
       
        </Text>
        <ol>
          <li>{t('privacy-policy.listItem-26')}</li>
          <li>{t('privacy-policy.listItem-27')}</li>
          <li>{t('privacy-policy.listItem-28')}</li>
          <li>{t('privacy-policy.listItem-29')}</li>
          <li>{t('privacy-policy.listItem-30')}</li>
        </ol>
      <Text>{t('privacy-policy.text-21')}</Text>
      <h3>{t('privacy-policy.heading-19')}</h3>
      <Text>{t('privacy-policy.text-22')}</Text>
      <Text>{t('privacy-policy.text-23')}</Text>
      <Text>{t('privacy-policy.text-24')}</Text>
      <Text>{t('privacy-policy.text-25')} </Text>
      <h3>{t('privacy-policy.heading-20')}</h3>
      <Text>{t('privacy-policy.text-26')}</Text>
      </PrivacyPolicy>
      </>
  )
}
export default Terms